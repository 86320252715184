import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Index',
        component: () => import('@/views/Index'),
    },
    {
        path: '/kbox',
        name: 'kbox',
        component: () => import('@/views/kbox'),
    },
    {
        path: '/ranking',
        name: 'ranking',
        component: () => import('@/views/ranking'),
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router