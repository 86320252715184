import Vue from 'vue'
import App from './App.vue'
import Web3 from 'web3'
import router from './router'

import Loading from './components/Loading'
Vue.use(Loading)

import alert from './components/Alert'
Vue.use(alert)

import './assets/font/font.css'

Vue.prototype.Web3 = Web3

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
