<template>
    <div class="loading-bg" v-if="show">
        <div class="container">
            <div class="loading"></div>
            <span style="color: #fed640d6;font-size: .2rem;">{{ msg }}</span>
        </div>

    </div>
</template>
<script>
export default {
    name: "loading",
    data() {
        return {
            show: false,
            msg: 'Loading...'
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style type="text/css">
.loading-bg{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000057;
}

.container {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    line-height: .1rem;
}

.loading {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-image: url(../../assets/images/loading-2.png);
    background-size: 100% 100%;
    animation: loading 1s linear infinite;
    margin: 0 auto;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>