<template>
  <div id="app">
   
    <router-view/>
  </div>
</template>
<script>
fnResize()
window.onresize = function() {
    fnResize()
}

function fnResize() {
  var deviceWidth = document.documentElement.clientWidth || window.innerWidth
  console.log(deviceWidth);
  if (deviceWidth >= 750) {
      deviceWidth = 750
  }
  if (deviceWidth <= 320) {
      deviceWidth = 320
  }
  document.documentElement.style.fontSize = (deviceWidth / 7.5 * 2) + 'px'
}

</script>
<style>
#app {
  font-family: AGENCYR, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
